import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProblemLayout from '../../components/ProblemLayout/ProblemLayout';

const Problem = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [problem, setProblem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [code, setCode] = useState('');
    const [output, setOutput] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const ACCESS_TOKEN = localStorage.getItem('token');

    const fetchProblem = useCallback(async (problemId) => {
        try {
            setLoading(true);
            const response = await fetch(`/v1/problems/${problemId}`, {
                headers: {
                    'Authorization': `Bearer ${ACCESS_TOKEN}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) throw new Error('문제 데이터를 불러오는 데 실패했습니다.');
            const data = await response.json();
            setProblem(data.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [ACCESS_TOKEN]);

    useEffect(() => {
        fetchProblem(id);
    }, [fetchProblem, id]);

    const handleCodeChange = (newCode) => setCode(newCode);

    const handleRunCode = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setOutput("로그인이 필요합니다. 다시 로그인해 주세요.");
            return;
        }
        setIsSubmitting(true);
        try {
            const response = await fetch('/v1/submit codes', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    language: 1,
                    code: code,
                    problem: parseInt(id)
                })
            });
            if (!response.ok) throw new Error('코드 제출에 실패했습니다.');
            const result = await response.json();
            setOutput(result.data);
        } catch (error) {
            setOutput(error.message || '코드 제출 중 오류가 발생했습니다.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePrev = () => {
        if (parseInt(id) > 1) navigate(`/problem/${parseInt(id) - 1}`);
    };

    const handleNext = () => {
        navigate(`/problem/${parseInt(id) + 1}`);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!problem) return <p>문제를 찾을 수 없습니다.</p>;

    return (
        <ProblemLayout
            title={problem.title}
            description={problem.description}
            restrictions={problem.restrictions}
            inputDescription={problem.inputDescription}
            outputDescription={problem.outputDescription}
            hints={problem.hints}
            difficulty={problem.difficulty}
            examples={problem.examples.map((ex) => ({
                input: ex.inputExample,
                output: ex.outputExample
            }))}
            code={code}
            onCodeChange={handleCodeChange}
            onRunCode={handleRunCode}
            output={isSubmitting ? "채점 중..." : output}
            onPrev={handlePrev}
            onNext={handleNext}
            isPrevDisabled={parseInt(id) === 1}
            isNextDisabled={output !== "정답입니다."}
        />
    );
};

export default Problem;
