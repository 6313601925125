import { createGlobalStyle } from 'styled-components';

const PretendardFont = createGlobalStyle`
  @font-face {
    font-family: "Pretendard-Regular";
    src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff");
    font-weight: 400;
    font-style: normal;
  }

  * {
    font-family: "Pretendard-Regular", sans-serif;
    margin: 0;
    padding: 0;
  }

  body {
    padding-top: 50px;
  }

  h1 {
  font-size: 60px;
  font-weight: 100;
  }

  h2 {
  font-size: 44px;
  font-weight: 100;
  }

  h3 {
  font-size: 34px;
  font-weight: 100;
  }

  h4 {
  font-size: 30px;
  font-weight: 100;
  }

  h5 {
  font-size: 28px;
  font-weight: 100;
  }

  h6 {
  font-size: 20px;
  font-weight: 100;
  }

  input, select {
  padding: 10px;
  border-radius: 10px;
  border:  1px solid black;
  margin-top: 10px;
}

button {
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid black;
  margin-top: 10px;
  cursor: pointer;
  background-color: black;
  color: white;
}

/* button:hover {
  background-color: yellow;
  color: black;
} */




`;

export default PretendardFont;
