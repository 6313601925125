import React from 'react';
import './App.css';
import { BrowserRouter, useLocation, matchPath } from 'react-router-dom';
import AppRoutes from './Routes';
import PretendardFont from './styles/fontStyles.ts';
import HeaderComponents from './components/Header/Header.jsx';
import FooterComponents from './components/Footer/Footer.jsx';

function App() {
  return (
    <BrowserRouter>
      <HeaderComponents />
      <PretendardFont />
      <div className="App">
        <ConditionalFooterApp />
      </div>
    </BrowserRouter>
  );
}

function ConditionalFooterApp() {
  const location = useLocation();
  
  // 푸터를 숨길 경로 패턴
  const hideFooterPaths = ['/Problem/:id'];

  // 현재 경로가 hideFooterPaths에 매치되는지 확인
  const shouldShowFooter = !hideFooterPaths.some((path) =>
    matchPath({ path, end: false }, location.pathname)
  );

  return (
    <>
      <AppRoutes />
      {shouldShowFooter && <FooterComponents />}
    </>
  );
}

export default App;
