import React from 'react';
import { Footer, FooterText } from './Footer.styles.ts';

const FooterComponent = () => {
    return (
        <Footer>
            <FooterText>ALGO-NIX</FooterText>
        </Footer>
    );
};

export default FooterComponent;
