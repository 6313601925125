import styled from 'styled-components';

export const TableContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
`;

export const ProblemTable = styled.table`
    width: 90%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    text-align: left;

    th {
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #666;
        border-bottom: 2px solid #ddd;
    }

    td {
        padding: 10px;
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #ddd;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    tr:hover {
        background-color: #f1f1f1;
    }
`;

export const Difficulty = styled.span`
    color: #007bff;
    font-weight: bold;
`;

export const Percentage = styled.span`
    color: #888;
`;

export const Solvers = styled.span`
    color: #333;
`;
