// src/pages/Problems/Problems.jsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    TableContainer,
    ProblemTable,
    Difficulty,
    // Percentage,
    // Solvers
} from './Problems.styles.ts';

const Problems = () => {
    const [problems, setProblems] = useState([]); // 상세 정보를 저장할 상태
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const ACCESS_TOKEN = localStorage.getItem('token');

    useEffect(() => {
        const fetchProblems = async () => {
            let problemId = 1; // 시작 ID
            const problemList = [];

            try {
                setLoading(true);

                while (problemId <= 5) { // 최대 5개의 문제만 가져오기
                    const response = await fetch(`/v1/problems/${problemId}`, {
                        headers: {
                            'Authorization': `Bearer ${ACCESS_TOKEN}`,
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        if (response.status === 404) {
                            // 문제가 더 이상 존재하지 않는 경우 반복문 종료
                            break;
                        } else {
                            throw new Error(`문제 ${problemId} 데이터를 불러오는 데 실패했습니다.`);
                        }
                    }

                    const data = await response.json();
                    problemList.push(data.data); // 문제 상세 데이터를 배열에 추가
                    problemId++; // 다음 문제 ID로 이동
                }

                setProblems(problemList); // 모든 문제 데이터를 상태에 저장
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProblems();
    }, [ACCESS_TOKEN]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <TableContainer>
            <ProblemTable>
                <thead>
                    <tr>
                        <th>상태</th>
                        <th>제목</th>
                        <th>난이도</th>
                        {/* <th>완료한 사람</th>
                        <th>정답률</th> */}
                    </tr>
                </thead>
                <tbody>
                    {problems.map((problem) => (
                        <tr key={problem.id}>
                            <td>출력</td>
                            <td>
                                <Link to={`/problem/${problem.id}`}>{problem.title}</Link>
                            </td>
                            <td>
                                <Difficulty>{`Lv. ${problem.difficulty}`}</Difficulty>
                            </td>
                            {/* <td>
                                <Solvers>{problem.solvers || "0명"}</Solvers>
                            </td>
                            <td>
                                <Percentage>{problem.rate || "0"}%</Percentage>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </ProblemTable>
        </TableContainer>
    );
};

export default Problems;
