// import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 99;
  color: white;
  transition: background-color 0.3s, color 0.3s;

  &.scrolled {
    background-color: white;
    color: black;
  }
`;

export const Logo = styled.img`
  width: 150px;
  filter: invert(100%);

  ${Header}.scrolled & {
    filter: invert(0%);
  }
`;

export const NavList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
`;

export const NavItem = styled.li`
  margin: 0 20px;
`;

export const NavLink = styled(Link)`
  color: white;
  text-decoration: none;

  ${Header}.scrolled & {
    color: black;
  }
`;