import styled from 'styled-components';

export const Register = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh;

    div {
        padding: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        border: 1px solid black;
    }

    span > input {
        margin-right: 10px;
    }
`;
