import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-family: 'Pretendard-Regular', sans-serif;
    background-color: #000; /* 배경 검은색 */
    color: #fff;
    height: 100vh; /* 화면 전체 높이 */
    margin: 0; /* 외부 여백 제거 */
    padding: 0 20px; /* 양쪽 패딩만 추가 */
    overflow: hidden; /* 스크롤 방지 */
    box-sizing: border-box;
    position: relative; /* 버튼 고정 기준 */
`;

export const LeftPanel = styled.div`
    flex: 1;
    padding: 20px;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #1e1e1e; /* 내부 패널 배경 어두운 회색 */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%; /* 부모 높이에 맞춤 */
    overflow: hidden; /* 스크롤 방지 */
    box-sizing: border-box;

    h2, h3, p {
        text-align: left; /* 왼쪽 정렬 */
    }
    
    & > div:not(:last-child) {
        border-bottom: 1px solid #444; /* 구분선 */
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
`;

export const Section = styled.div`
    margin-bottom: 20px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    & th, & td {
        padding: 8px;
        border: 1px solid #ddd;
        text-align: center;
        color: #fff;
    }
    & th {
        background-color: #444; /* 테이블 헤더 배경 어두운 회색 */
    }
`;

export const RightPanel = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #1e1e1e; /* 내부 패널 배경 어두운 회색 */
    height: 100%; /* 부모 높이에 맞춤 */
    overflow: hidden; /* 스크롤 방지 */
    box-sizing: border-box;
`;

export const Button = styled.button`
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #d4af37; /* 버튼 금색 */
    color: #000;
    border: none;
    border-radius: 4px;
    font-family: 'Pretendard-Regular', sans-serif;
    &:hover {
        background-color: #b08d2e;
    }
`;

export const Output = styled.div`
    padding: 10px;
    background-color: #2b2b2b; /* 실행 결과 패널 배경 어두운 회색 */
    color: #d4d4d4; /* 텍스트 밝은 회색 */
    border-radius: 4px;
    margin-top: 10px;
`;

export const NavButton = styled.button`
    position: fixed;
    bottom: 20px; /* 버튼 위치 하단 고정 */
    padding: 10px 20px;
    cursor: pointer;
    background-color: ${(props) => (props.disabled ? '#555' : '#d4af37')}; /* 버튼 금색 */
    color: #000;
    border: none;
    border-radius: 4px;
    font-family: 'Pretendard-Regular', sans-serif;

    &:hover {
        background-color: ${(props) => (props.disabled ? '#555' : '#b08d2e')};
    }
    &:disabled {
        cursor: not-allowed;
    }

    /* 위치 지정 */
    ${(props) => props.position === 'left' && `
        left: 20px;
    `}
    ${(props) => props.position === 'right' && `
        right: 20px;
    `}
`;
