import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main/Main";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import MyPage from "./pages/MyPage/MyPage";
import Problem from "./pages/Problem/Problem";
import Problems from "./pages/Problems/Problems";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/MyPage" element={<MyPage />} />
            <Route path="/Problems" element={<Problems />} />
            <Route path="/Problem/:id" element={<Problem />} />
        </Routes>
    );
};

export default AppRoutes;
