import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import {
    Container,
    LeftPanel,
    RightPanel,
    Section,
    Table,
    Button,
    Output,
    NavButton,
} from './ProblemLayout.styles.ts';

const ProblemLayout = ({ 
    title, 
    description, 
    restrictions, 
    inputDescription, 
    outputDescription, 
    hints,
    difficulty,
    examples, 
    code = "", 
    onCodeChange, 
    onRunCode, 
    output,
    onPrev,
    onNext,
    isPrevDisabled,
    isNextDisabled,
}) => {
    const handleCodeChange = (value) => {
        if (value !== undefined && value !== null) {
            onCodeChange(value);
        }
    };

    return (
        <Container>
            <LeftPanel>
                <h2>{title}</h2>
                <Section>
                    <h3>문제 설명</h3>
                    <p>{description}</p>
                </Section>
                <Section>
                    <h3>제약사항</h3>
                    <p>{restrictions}</p>
                </Section>
                <Section>
                    <h3>입력 설명</h3>
                    <p>{inputDescription}</p>
                </Section>
                <Section>
                    <h3>출력 설명</h3>
                    <p>{outputDescription}</p>
                </Section>
                <Section>
                    <h3>힌트</h3>
                    <p>{hints}</p>
                </Section>
                <Section>
                    <h3>난이도</h3>
                    <p>{`레벨 ${difficulty}`}</p>
                </Section>
                <Section>
                    <h3>입출력 예</h3>
                    <Table>
                        <thead>
                            <tr>
                                <th>입력</th>
                                <th>출력</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examples.map((example, index) => (
                                <tr key={index}>
                                    <td>{example.input}</td>
                                    <td>{example.output}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Section>
            </LeftPanel>

            <RightPanel>
                <h3>solution.py</h3>
                <MonacoEditor
                    height="200px"
                    width="100%"
                    theme="vs-dark"
                    defaultLanguage="python"
                    value={code || ""}
                    onChange={(value) => handleCodeChange(value || "")}
                    options={{
                        fontSize: 16,
                        minimap: { enabled: false },
                    }}
                />

                <Button onClick={onRunCode}>제출하기</Button>
                <Output>
                    <h3>실행 결과</h3>
                    <p>{output}</p>
                </Output>

                {/* 각 모서리에 위치할 이전, 다음 버튼 */}
                <NavButton onClick={onPrev} disabled={isPrevDisabled} position="left">이전</NavButton>
                <NavButton onClick={onNext} disabled={isNextDisabled} position="right">다음</NavButton>
            </RightPanel>
        </Container>
    );
};

export default ProblemLayout;
