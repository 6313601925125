import React, { useState } from "react";
import { Register } from "./Register.styles.ts";
import { RegisterUser } from '../../api/auth.js';

const Main = () => {
    const [username, setUsername] = useState("");
    const [nickname, setNickname] = useState("");
    const [password, setPassword] = useState("");
    const [checkPassword, setCheckPassword] = useState("");
    const [email, setEmail] = useState("");
    const [organization, setOrganization] = useState("");
    const [organizationVisibility, setOrganizationVisibility] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleRegister = async () => {
        if (!username) {
            setErrorMessage("아이디를 입력해주세요!");
        } else if (!password) {
            setErrorMessage("비밀번호를 입력해주세요!");
        } else if (!checkPassword) {
            setErrorMessage("비밀번호를 확인해주세요.");
        } else if (!email) {
            setErrorMessage("이메일을 입력해주세요!");
        } else if (password !== checkPassword) {
            setErrorMessage("비밀번호를 다시 확인해주세요.");
        } else {
            try {
                const userData = await RegisterUser(
                    username,
                    password,
                    email,
                    nickname,
                    organization,
                    organizationVisibility
                );
                console.log("회원가입 성공: " + JSON.stringify(userData));
                setErrorMessage(""); // 성공 시 에러 메시지 초기화
                window.location.href = "/Login";
                // 성공 후 추가 작업 (예: 대시보드로 리다이렉트)
            } catch (error) {
                setErrorMessage("회원가입 실패: " + error.message);
            }
        }
    };

    return (
        <Register>
            <div>
                <h4>회원가입</h4>
                <p id="error-message" style={{ color: "red" }}>{errorMessage}</p>
                <input
                    id="username"
                    type="text"
                    placeholder="아이디를 입력해주세요."
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    id="nickname"
                    type="text"
                    placeholder="닉네임을 입력해주세요."
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                />
                <input
                    id="email"
                    type="email"
                    placeholder="이메일을 입력해주세요."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    id="organization"
                    type="text"
                    placeholder="소속을 입력해주세요."
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                />
                <select
                    value={organizationVisibility}
                    onChange={(e) => setOrganizationVisibility(e.target.value)}
                >
                    <option value="">소속 공개 여부를 선택해주세요.</option>
                    <option value="공개">공개</option>
                    <option value="비공개">비공개</option>
                </select>
                <input
                    id="password"
                    type="password"
                    placeholder="비밀번호를 입력해주세요."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    id="check-password"
                    type="password"
                    placeholder="비밀번호를 확인해주세요."
                    value={checkPassword}
                    onChange={(e) => setCheckPassword(e.target.value)}
                />
                <button onClick={handleRegister}>회원가입</button>
            </div>
        </Register>
    );
};

export default Main;
