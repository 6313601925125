import styled, { keyframes }from 'styled-components';

export const textSlide = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const Slider = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(40, 40, 40);
  position: relative;
`;

export const SlideText = styled.div`
  font-size: 40px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${textSlide} 15s infinite;
  color: white;

  h1 {
    margin: 40px 0;
    font-style: italic;
  }
`;

export const SlideText1 = styled(SlideText)`
  animation-delay: 0s;
`;

export const SlideText2 = styled(SlideText)`
  animation-delay: 5s;
`;

export const SlideText3 = styled(SlideText)`
  animation-delay: 10s;
`;

export const Service = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 200px;
  background-color: black;
  color: white;

  h2 {
    margin-bottom: 30px;
  }
`;

export const ServiceContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ServiceContent = styled.div`
  margin: 10px;
  background-color: white;
  width: 400px;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 5px 5px 10px gray;

  img {
    width: 100%;
  }

  div {
    border-radius: 20px 20px 0 0;
    padding: 30px;
    text-align: left;
    color: black;
    background-color: rgb(240, 240, 240);
  }

  span {
    background-color: yellow;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }

  h3 {
    margin: 20px 0 10px 0;
  }
`;