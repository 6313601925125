import React, { useState, useEffect } from "react";
import { Login } from "./Login.styles.ts";
import { loginUser, isAuthenticated } from '../../api/auth.js';

const Main = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // 페이지 접근 시 로그인 상태 확인
    useEffect(() => {
        if (isAuthenticated()) {
            alert("로그인 상태입니다.");
            window.location.href = "/"; // 메인 페이지로 리다이렉트
        }
    }, []);

    const handleLogin = async () => {
        if (!username) {
            setErrorMessage("아이디를 입력해주세요!");
        } else if (!password) {
            setErrorMessage("비밀번호를 입력해주세요!");
        } else {
            try {
                const userData = await loginUser(username, password);
                setErrorMessage("");
                window.location.href = "/"; // 로그인 성공 시 메인 페이지로 리다이렉트
            } catch (error) {
                setErrorMessage("로그인 실패: " + error.message);
            }
        }
    };

    return (
        <Login>
            <div>
                <h4>로그인</h4>
                <p style={{ color: 'red' }}>{errorMessage}</p>
                <input
                    id="username"
                    type="text"
                    placeholder="아이디를 입력해주세요"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    id="password"
                    type="password"
                    placeholder="비밀번호를 입력해주세요"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>로그인</button>
            </div>
        </Login>
    );
};

export default Main;
