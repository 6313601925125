import React, { useState, useEffect } from "react";
import logo from "../../images/Algonix_logo.svg";
import {
    Header,
    Logo,
    NavList,
    NavItem,
    NavLink,
} from './Header.styles.ts';
import { isAuthenticated } from '../../api/auth'; // 로그인 상태 확인 함수 가져오기

const HeaderComponents = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    // 로그인 상태 확인
    useEffect(() => {
        setLoggedIn(isAuthenticated()); // 페이지 로드 시 로그인 상태 확인
    }, []);

    // 스크롤 이벤트 감지
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsScrolled(scrollPosition > 50); // 스크롤이 50px 이상이면 true로 설정
        };

        // 스크롤 이벤트 리스너 추가
        window.addEventListener("scroll", handleScroll);

        // 컴포넌트가 언마운트 될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // 로그아웃 처리
    const handleLogout = () => {
        localStorage.removeItem('token'); // 토큰 삭제
        setLoggedIn(false); // 로그인 상태 업데이트
        window.location.href = "/"; // 메인 페이지로 이동
    };

    return (
        <Header className={isScrolled ? 'scrolled' : ''}>
            <NavList>
                <NavItem>
                    <NavLink to="/">
                        <Logo src={logo} alt="Algonix Logo" />
                    </NavLink>
                </NavItem>
                <NavItem><NavLink to="/Problems">연습하기</NavLink></NavItem>
                <NavItem><NavLink to="/">도전하기</NavLink></NavItem>
                <NavItem><NavLink to="/">함께하기</NavLink></NavItem>
                <NavItem><NavLink to="/">보고 배우기</NavLink></NavItem>
            </NavList>
            <NavList>
                {loggedIn ? (
                    <>
                        <NavItem><NavLink to="/Mypage">마이페이지</NavLink></NavItem>
                        <NavItem><NavLink to="/" onClick={handleLogout}>로그아웃</NavLink></NavItem>
                    </>
                ) : (
                    <>
                        <NavItem><NavLink to="/Login">로그인</NavLink></NavItem>
                        <NavItem><NavLink to="/Register">회원가입</NavLink></NavItem>
                    </>
                )}
            </NavList>
        </Header>
    );
}

export default HeaderComponents;
