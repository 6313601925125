// import React from 'react';
import styled from 'styled-components';

export const Footer = styled.footer`
  text-align: center;
  background-color: black;
  color: white;
  padding: 20px;
`;

export const FooterText = styled.p`
  padding: 8px;
`;
