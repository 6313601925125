import React from "react";
import {
    Slider,
    SlideText1,
    SlideText2,
    SlideText3,
    Service,
    ServiceContents,
    ServiceContent
} from "./Main.styles.ts";
import firstImg from "../../images/프로그래밍언어.png";
import secondImg from "../../images/알고리즘.png";
import thirdImg from "../../images/커뮤니티사이트.png";

const Main = () => {
    return (
        <>
            <Slider>
                <SlideText1>
                    <h1>ALGO-NIX를 통해</h1>
                    <h1>다양한 알고리즘 문제를</h1>
                    <h1>경험해보세요!</h1>
                </SlideText1>

                <SlideText2>
                    <h1>다양한 언어로</h1>
                    <h1>문제를 풀어볼 수 있는</h1>
                    <h1>ALGO-NIX</h1>
                </SlideText2>

                <SlideText3>
                    <h1>다른 사람들과 소통하며</h1>
                    <h1>문제를 풀어나가는</h1>
                    <h1>ALGO-NIX</h1>
                </SlideText3>
            </Slider>

            <Service>
                <h2>Algo-nix의 서비스</h2>
                <ServiceContents>
                    <ServiceContent>
                        <div>
                            <span>첫번째</span>
                            <h3>다양한 언어 체험</h3>
                            <p>여러 프로그래밍 언어를 지원하여 사용자가 자신이 원하는 언어로 알고리즘 문제를 풀 수 있는 환경을 제공합니다.</p>
                        </div>
                        <img src={firstImg} alt="" />
                    </ServiceContent>
                    <ServiceContent>
                        <div>
                            <span>두번째</span>
                            <h3>다양한 알고리즘 문제</h3>
                            <p>초급부터 고급까지 다양한 난이도의 문제를 제공하여 사용자 수준에 맞춘 학습을 지원합니다. 모든 사용자가 실력에 맞는 문제로 성장할 수 있습니다.</p>
                        </div>
                        <img src={secondImg} alt="" />
                    </ServiceContent>
                    <ServiceContent>
                        <div>
                            <span>세번째</span>
                            <h3>커뮤니티 형성</h3>
                            <p>혼자 문제를 풀고 끝나는 것이 아니라, 다른 사용자들과 소통하며 더 나은 개발자고 성장할 수 있는 커뮤니티를 제공합니다.</p>
                        </div>
                        <img src={thirdImg} alt="" />
                    </ServiceContent>
                </ServiceContents>
            </Service>
        </>
    );
};

export default Main;
