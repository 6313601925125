export const RegisterUser = async (username, password, email, nickname, organization, visibility) => {
    try {
        const response = await fetch('/v1/auth/signup', { // 상대 경로로 변경
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
                email,
                nickname,
                organization,
                visibility,
                statusMessage: "Hello! I'm using this service."
            }),
            credentials: 'include'
        });

        if (response.status === 409) {
            // 409 Conflict 상태 코드 처리: 중복된 아이디 경고 메시지
            throw new Error("중복된 아이디입니다.");
        }

        if (!response.ok) {
            throw new Error('회원가입 요청 실패');
        }

        const data = await response.json();
        alert("회원가입 성공");
        return data;
    } catch (error) {
        alert(error.message || '회원가입 요청 실패'); // 오류 메시지를 경고로 표시
        throw new Error(error.message);
    }
};


export const loginUser = async (username, password) => {
    if (!username) {
        throw new Error("아이디를 입력해주세요!");
    }
    if (!password) {
        throw new Error("비밀번호를 입력해주세요!");
    }

    try {
        const response = await fetch('/v1/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (response.status === 401) {
            throw new Error("아이디 또는 비밀번호가 잘못되었습니다.");
        }

        if (!response.ok) {
            throw new Error("로그인 요청 실패");
        }

        const data = await response.json();

        // 토큰이 존재할 경우 localStorage에 저장
        if (data.data.token) {
            localStorage.setItem('token', data.data.token);
        } else {    
            throw new Error("토큰이 존재하지 않습니다.");
        }

        return data; // 로그인 성공 시 사용자 데이터 반환
    } catch (error) {
        throw new Error(error.message || "로그인 요청 실패");
    }
};

export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return token !== null;
};
