import React from "react";

const Main = () => {
    return (
        <div>
            <h1>마이페이지입니다.</h1>
        </div>
    );
};

export default Main;
